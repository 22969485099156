
import { Vue } from "vue-class-component";
import axios from "@/plugins/axios";

export default class PackagePage extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  account = {};
  packages = [];
  dialogEditExpiryDate = false;
  expiryDate = "";
  selectPackage = "";
  id = "";
  async created() {
    this.getAccountPackage();
  }

  async getAccountPackage() {
    await this.$store.dispatch("setLoading", true, { root: true });
    const res = await axios.post("user/account/detail",{}, {
      headers: this.headers,
    });
    if (res) {
      this.id = res.data._id;
      this.packages = res.data.connectionData;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  openModal(item: any) {
    this.expiryDate = item.expiryDate;
    this.dialogEditExpiryDate = true;
    this.selectPackage = item._id;
  }
  async savePackage() {
    this.packages.forEach((item: any) => {
      if (item._id === this.selectPackage) {
        item.expiryDate = this.expiryDate;
      }
    });
    let formData = {
      _id: this.id,
      connectionData: this.packages,
    };
    await axios
      .put("user/account", formData, {
        headers: this.headers,
      })
      .then(
        (response: any) => {
          this.$message.success(response.message);
          this.dialogEditExpiryDate = false;
        },
        (response: any) => {
          this.$message.warning(response.message);
          this.dialogEditExpiryDate = false;
        }
      );
  }
}
